<template>
  <transition name="smooth">
    <div
      v-click-outside="outside"
      class="con-vs-dropdown--menu vs-dropdown-menu konecta-dropdown"
    >
      <div class="vs-dropdown--custom vs-dropdown--menu">
        <slot></slot>
      </div>
      <div class="vs-dropdown--menu--after" style="top: 10px;"></div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'KonectaDropdown',
  props: {
    clickOutside: Function
  },
  methods: {
    outside: function() {
      if (this.clickOutside()) this.clickOutside()
    }
  }
}
</script>

<style lang="scss" scoped>
.vs-dropdown--menu--after {
  right: 20px;
}

.smooth-enter-active,
.smooth-leave-active {
  transition: all 0.2s ease;
}
.smooth-enter,
.smooth-leave-to {
  opacity: 0;
}
</style>
